import { GetStaticProps } from "next"
import React from "react"
import NotFoundPage from "../../components/notfound/NotFoundPage";

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      messages: (await import(`../../messages/${locale}.json`)).default,
    },
  }
}

const notFoundPage = () => {
  return <NotFoundPage/>
}

export default notFoundPage
