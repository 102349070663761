import { Button, Typography } from "@material-ui/core";
import { useTranslations } from "next-intl";
import Link from "next/link";
import styled from "styled-components";

//Styles
const StyledBackground = styled.div`
  background-image: url("/notfoundimage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 250px;
`;
const StyledSubtitle = styled(Typography)`
  font-size: 34px;
  line-height: 40px;
  margin-top: 20px;
  margin-bottom: 52px;
`;
const StyledButton = styled(Button)`
  font-size: 16px;
  border-radius: 0;
  padding: 14px;
  margin-bottom: 260px;
`;
const StyledErrorText = styled(Typography)`
  font-size: 245px;
  color: rgba(255, 255, 255, 0.4);
  align-self: start;
  margin-right: 100px;
  
  ${(p) => p.theme.breakpoints.down('md')} {
    font-size: 150px;
    margin-right: 16px;
  }
`;

const NotFoundPage = () => {
  const t = useTranslations("notFoundPage");

  //Render
  return (
    <StyledBackground>
      <Typography variant="h1">
        {t("wow")}
      </Typography>
      <StyledSubtitle>
        {t("goBack")}
      </StyledSubtitle>
      <Link href="/">
        <StyledButton variant="contained">
          {t("homePage")}
        </StyledButton>
      </Link>
      <StyledErrorText>
        404
      </StyledErrorText>
    </StyledBackground>
  );
};

export default NotFoundPage;
